<template>
  <div class="certificate">
    <div class="user-tit">我的证书</div>
    <div class="tab-list">
      <div class="tab-list-item" :class="curTabIndex == index ? 'active' : ''" v-for="(item,index) in tabList" :key="item.id" @click="changeTab(index)">
        <p>{{ item.name }}</p>
        <div></div>
      </div>
    </div>
    <div class="certificate-list" v-if="dataList.length && !loading">
      <div class="certificate-list-item" v-for="item in dataList" :key="item.id">
        <img :src="item.certImg" width="332" />
        <div class="certificate-list-item-name">{{ item.category && item.category.name }}</div>
<!--        <div class="certificate-list-item-desc">当前考试次数: 剩余 {{item.exam_remain_num}} 次</div>-->
<!--        <div class="certificate-list-item-btn"  @click="downCert(item)">下载证书</div>-->
        <div class="certificate-list-item-btn" v-if="item.cert_status == 2 || item.cert_status == 3" @click="downCert(item)">下载证书</div>
        <div class="certificate-list-item-btn gray" v-else>{{ certStatus[item.cert_status] }}</div>

      </div>
    </div>
    <div class="no-data" v-if="!dataList.length && !loading">
      <img src="../../assets/images/common/no-data.png" width="223" height="129" />
      <p>还没有证书哦~</p>
    </div>
    <div style="height: 300px;position: relative" v-loading="loading" v-if="loading"></div>
  </div>
</template>

<script>
import util from '@/utils/index.js'
export default {
  name: "certificate",
  data(){
    return {
      tabList:[
        {
          name:'全部',
          id:'0',
        },
        {
          name:'已失效',
          id:'1',
        },
      ],
      curTabIndex:0,
      dataList:[],
      showUrl:process.env.VUE_APP_SHOW_URL + '?file=',
      certStatus:{},
      loading:true,
    }
  },
  created(){
    this.getList();
  },
  methods:{
    changeTab(index){
      this.curTabIndex = index;
      this.getList();
    },
    getList(){
      let params = {
        // cert_status:1,
        has_cert:1,
        cert_is_expired:this.tabList[this.curTabIndex].id
      };
      this.loading = true;
      this.api.user.userSelfList(params).then((res)=>{
        this.loading = false;
        this.dataList = res.list;
        this.dataList.forEach((item)=>{
          if(!item.cert_img.indexOf('http')  == 0){ //不以http开头
            item.certImg = this.showUrl + item.cert_img;
          }else{
            item.certImg = item.cert_img;
          }
        })
        this.certStatus = res.cert_status;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    downCert(data){
      let params = {
        file:data.cert_pdf
      };
      this.api.user.download(params).then((res)=>{
        util.downLoad(res)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.certificate{
  .user-tit{
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  .certificate-list{
    width: 100%;
    margin-bottom: 30px;
    @include flex(row,flex-start,flex-start);
    flex-wrap: wrap;
    .certificate-list-item{
      margin-top: 30px;
      margin-right: 20px;
      padding: 20px;
      width: 330px;
      box-shadow: 0px 0px 20px 0px rgba(0,35,136,0.08);
      border-radius: 10px;

      .certificate-list-item-name{
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
      }
      .certificate-list-item-desc{
        margin-top: 8px;
        font-size: 12px;
        color: #666666;
      }
      .certificate-list-item-btn{
        margin-top: 20px;
        @include btn(120px,38px,19px,14px,linear-gradient(222deg, #3A6CFF, #004FEB));
      }
    }
    .certificate-list-item:nth-child(3n){
      margin-right: 0;
    }
  }
}
</style>
